import Camur from "@/utils/camurutils";
const MomentTimezone = require("moment-timezone");

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export default class LangUtils {
  constructor(app) {
    this.app = app;
  }

  get txt() {
    return this.app.$txt;
  }

  http_status_to_text(status) {
    if (status === 200) return this.txt.http_status_200;
    if (status === 201) return this.txt.http_status_201;
    if (status === 400) return this.txt.http_status_400;
    if (status === 401) return this.txt.http_status_401;
    if (status === 403) return this.txt.http_status_403;
    if (status === 404) return this.txt.http_status_404;
    if (status === 405) return this.txt.http_status_405;
    if (status === 409) return this.txt.http_status_409;
    if (status === 428) return this.txt.http_status_428;
    if (status === 500) return this.txt.http_status_500;
    if (status === 503) return this.txt.http_status_503;
    return this.txt.http_status_unknown;
  }

  yes_no_string(val) {
    return val ? this.txt.yes : this.txt.no;
  }

  on_off_string(val) {
    return val ? this.txt.on : this.txt.off;
  }

  /* TIME UTILITIES */

  get_time_locale() {
    let locale = this.app.settings.time_locale;
    if (locale == "def") {
      locale = locale = window.navigator.languages[0];
    }
    return locale;
  }

  get_local_timezone() {
    return MomentTimezone.tz.guess();
  }

  remove_timezone_info_from_timestr(timestr) {
    return timestr.substring(0, 19);
  }

  // Working time = the timespace which is used to
  // display dates in this project.
  convert_working_time_to_utc(time) {
    let local_tz = this.get_working_tz();
    return MomentTimezone.tz(time, local_tz)
      .utc()
      .format();
  }

  is_valid_timezone(timezone) {
    if (MomentTimezone.tz(timezone) == null) {
      console.error("Unsupported timezone: " + timezone);
      return false;
    }
    return true;
  }

  convert_time_to_timezone(time, timezone) {
    console.assert(
      this.is_valid_timezone(timezone),
      `Invalid timezone: ${timezone}`
    );
    let local_time = MomentTimezone.utc(time)
      .tz(timezone)
      .format();
    return this.remove_timezone_info_from_timestr(local_time);
  }

  get_project_timezone(prj = null) {
    if (prj == null) prj = app.$appm.store.sltd.project;
    if (prj == null) {
      // Default to local timezone when there is no
      // selected project, for example in settings and
      // the project overview and so on
      console.log("get_project_timezone -> get_local_timezone");
      return this.get_local_timezone();
    }
    // When the projects has not been properly set up
    // with a timezone
    if(prj.dto.info.timezone === null) {
      return this.get_local_timezone();
    }

    return prj.dto.info.timezone;
  }

  get_controller_state(state) {
    switch (state) {
      case Camur.controller_state.state_listening_to_commands: {
        return this.txt.state_listening_to_commands;
      }
      case Camur.controller_state.state_connected: {
        return this.txt.connected;
      }
      case Camur.controller_state.state_discovering: {
        return this.txt.state_discovering;
      }
      case Camur.controller_state.state_repairing: {
        return this.txt.state_repairing;
      }
      case Camur.controller_state.state_controller_busy: {
        return this.txt.state_controller_busy;
      }
      case Camur.controller_state.state_disconnected: {
        return this.txt.state_disconnected;
      }
      case Camur.controller_state.state_not_connected: {
        return this.txt.state_not_connected;
      }
      case Camur.controller_state.state_uninitialized: {
        return this.txt.state_uninitialized;
      }
      case Camur.controller_state.state_no_assigned_device: {
        return this.txt.state_no_assigned_device;
      }
      case Camur.controller_state.state_initialize: {
        this.txt.state_initialize;
      }
      case Camur.controller_state.state_failure: {
        return this.txt.state_failure;
      }
      case Camur.controller_state.state_discover_time_stamp: {
        return this.txt.state_discover_time_stamp;
      }
      case Camur.controller_state.state_discover_version: {
        return this.txt.state_discover_version;
      }
      case Camur.controller_state.state_discover_ip: {
        return this.txt.state_discover_ip;
      }
      case Camur.controller_state.state_discover_ping_interval: {
        return this.txt.state_discover_ping_interval;
      }
      case Camur.controller_state.state_discover_monitor: {
        return this.txt.state_discover_monitor;
      }
      case Camur.controller_state.state_discover_decay: {
        return this.txt.state_discover_decay;
      }
      case Camur.controller_state.state_discover_lpr: {
        return this.txt.state_discover_lpr;
      }
      case Camur.controller_state.state_discover_resmes: {
        return this.txt.state_discover_resmes;
      }
      case Camur.controller_state.state_discover_zra: {
        return this.txt.state_discover_zra;
      }
      case Camur.controller_state.state_discover_schedule: {
        return this.txt.state_discover_schedule;
      }
      case Camur.controller_state.state_discover_recording: {
        return this.txt.state_discover_recording;
      }
      case Camur.controller_state.state_discover_node_info: {
        return this.txt.state_discover_node_info;
      }
      case Camur.controller_state.state_discover_ack_timestamp: {
        return this.txt.state_discover_ack_timestamp;
      }
      case Camur.controller_state.state_discover_done: {
        return this.txt.state_discover_done;
      }
    }
    return "Unknown state: " + state;
  }

  get_working_tz(display_time = null) {
    if (display_time == null) display_time = app.settings.display_time;
    //console.log("get_working_tz:"+display_time);
    let timezone = "UTC";
    switch (display_time) {
      case "prj":
        timezone = this.get_project_timezone();
        break;
      case "utc":
        timezone = "UTC";
        break;
      default:
        timezone = this.get_local_timezone();
    }
    return timezone;
  }

  // HOW THE TIME FORMATING WORKS
  // 1. print_date_time takes:
  //    a) any time string OR a
  //    b) a JS timestamp integer (UTC ms since 1 Jan 1970) OR
  //    c) a JS Date object
  // 2. If no timezone is specified in a timestring
  //    it is assumed to be in local time
  // 3. JS-timestamps are always UTC
  //
  // 4. The app settings specify which timezone
  //    to use when printing the timestamp
  //    timezones. The default behaviour converts
  // 5. With the options object, settings on whether to
  //    print date, time and seconds can be set.
  // 7. options.use_iso_formatting will overwrite all other settings
  //
  // 8. If options.convert_timezone is set to false, any timezone data
  //    will be ignored
  print_date_time(time, options = null, display_time = null) {
    if (!time) {
      // console.error("Can not print date: " + time);
      return this.app.$txt.unknown_time;
    }

    let default_options = {
      show_date: true,
      show_time: true,
      show_seconds: false,
      use_iso_formatting: false,
      show_unix_zero_as_unknown: true
    };

    if (!options) {
      options = default_options;
    } else {
      options = {
        ...default_options,
        ...options
      };
    }

    if (options.use_iso_formatting) {
      return new Date(time).toISOString();
    }

    // Timezones
    let timezone = this.get_working_tz(display_time);
    let converted_time = this.convert_time_to_timezone(time, timezone);
    let date = new Date(converted_time);

    if (options.show_unix_zero_as_unknown && date.getTime() < ONE_DAY_MS)
      return this.app.$txt.unknown_time;

    // Date
    let datestring = "";
    if (options.show_date) {
      datestring = date.toLocaleDateString(this.get_time_locale());
    }

    // Time
    let timestring = "";
    if (options.show_time) {
      let time_options = { hour: "2-digit", minute: "2-digit" };
      if (options.show_seconds) time_options.second = "2-digit";
      timestring = date.toLocaleTimeString(
        this.get_time_locale(),
        time_options
      );
    }

    // Separator
    let separator = datestring.length > 0 && timestring.length > 0 ? " " : "";

    // Combine
    let result = `${datestring}${separator}${timestring}`;
    return result;
  }

  print_date_time_sec(time) {
    return this.print_date_time(time, { show_seconds: true, show_date: true });
  }

  print_time(time) {
    return this.print_date_time(time, { show_date: false });
  }

  print_date(time_string) {
    return this.print_date_time(time_string, { show_time: false });
  }

  print_time_sec(time_string) {
    return this.print_date_time(time_string, {
      show_date: false,
      show_seconds: true
    });
  }

  print_unit(unit_code) {
    switch (unit_code) {
      case "v":
        return this.txt.unit_volt;
      case "mv":
        return this.txt.unit_millivolt;
      case "a":
        return this.txt.unit_ampere;
      case "ma":
        return this.txt.unit_milliampere;
      case "tc":
        return this.txt.unit_degree_celsius;
      case "tf":
        return this.txt.unit_degree_fahrenheit;
      case "sqm":
        return this.txt.unit_square_meter;
      case "sqft":
        return this.txt.unit_square_feet;
      default:
        return "?";
    }
  }

  get_job_type_name(job_type) {
    let name = this.txt.default_job_desc;
    switch (job_type) {
      case Camur.job_type.getControllerStatus:
        name = "getControllerStatus";
        break;
      case Camur.job_type.getControllerLog:
        name = "getControllerLog";
        break;
      case Camur.job_type.recordingModeChange:
        name = "recordingModeChange";
        break;
      case Camur.job_type.getControllerRecordingData:
        name = "getControllerRecordingData";
        break;
      case Camur.job_type.setControllerRecordingSettings:
        name = "setControllerRecordingSettings";
        break;
      case Camur.job_type.setControllerRecordingDecayParams:
        name = "setControllerRecordingDecayParams";
        break;
      case Camur.job_type.setControllerRecordingLprParams:
        name = "setControllerRecordingLprParams";
        break;
      case Camur.job_type.setControllerRecordingZraParams:
        name = "setControllerRecordingZraParams";
        break;
      case Camur.job_type.setControllerRecordingResMesParams:
        name = "setControllerRecordingResMesParams";
        break;
      case Camur.job_type.setControllerSwitchOffOnAlarm:
        name = "setControllerSwitchOffOnAlarm";
        break;
      case Camur.job_type.setControllerRecordingMonitorParams:
        name = "setControllerRecordingMonitorParams";
        break;
      case Camur.job_type.setControllerRecordingScheduleParams:
        name = "setControllerRecordingScheduleParams";
        break;
      case Camur.job_type.discoverControllerMonitorParams:
        name = "discoverControllerMonitorParams";
        break;
      case Camur.job_type.discoverControllerDecayParams:
        name = "discoverControllerDecayParams";
        break;
      case Camur.job_type.discoverControllerLprParams:
        name = "discoverControllerLprParams";
        break;
      case Camur.job_type.discoverControllerZraParams:
        name = "discoverControllerZraParams";
        break;
      case Camur.job_type.discoverControllerResMesParams:
        name = "discoverControllerResMesParams";
        break;
      case Camur.job_type.discoverControllerSwitchOffOnAlarm:
        name = "discoverControllerSwitchOffOnAlarm";
        break;
      case Camur.job_type.discoverControllerScheduleParams:
        name = "discoverControllerScheduleParams";
        break;
      case Camur.job_type.discoverControllerAckTimestamp:
        name = "discoverControllerAckTimestamp";
        break;
      case Camur.job_type.discoverControllerNodes:
        name = "discoverControllerNodes";
        break;
      case Camur.job_type.discoverControllerRecordingStatus:
        name = "discoverControllerRecordingStatus";
        break;
      case Camur.job_type.discoverControllerTimestamp:
        name = "discoverControllerTimestamp";
        break;
      case Camur.job_type.discoverControllerVersion:
        name = "discoverControllerVersion";
        break;
      case Camur.job_type.upgradeControllerFirmware:
        name = "upgradeControllerFirmware";
        break;
      case Camur.job_type.controllerReboot:
        name = "controllerReboot";
        break;
      case Camur.job_type.controllerPrepareBoot:
        name = "controllerPrepareBoot";
        break;
      case Camur.job_type.controllerCancelGetRecordingData:
        name = "controllerCancelGetRecordingData";
        break;
      case Camur.job_type.controllerPingPeriodReset:
        name = "controllerPingPeriodReset";
        break;
      case Camur.job_type.controllerResetCAN:
        name = "controllerResetCAN";
        break;
      case Camur.job_type.setNodePowerSupplyParams:
        name = "setNodePowerSupplyParams";
        break;
      case Camur.job_type.setFixVoltOutputMode:
        name = "setFixVoltOutputMode";
        break;
      case Camur.job_type.setFixVoltOutputOnOff:
        name = "setFixVoltOutputOnOff";
        break;
      case Camur.job_type.setFixVoltSwitchOffOnAlarm:
        name = "setFixVoltSwitchOffOnAlarm";
        break;
      case Camur.job_type.setFixVoltOutputRecovery:
        name = "setFixVoltOutputRecovery";
        break;
      case Camur.job_type.setFixVoltOutputVoltage:
        name = "setFixVoltOutputVoltage";
        break;
      case Camur.job_type.setFixVoltOutputCurrent:
        name = "setFixVoltOutputCurrent";
        break;
      case Camur.job_type.setFixVoltOutputCurrent:
        name = "setFixVoltOutputCurrent";
        break;
      case Camur.job_type.discoverNodePowerSupplyParams:
        name = "discoverNodePowerSupplyParams";
        break;
      case Camur.job_type.setAlarmStatus:
        name = "setAlarmStatus";
        break;
      case Camur.job_type.setNodeCurrTransParams:
        name = "setNodeCurrTransParams";
        break;
      case Camur.job_type.discoverNodeCurrTransParams:
        name = "discoverNodeCurrTransParams";
        break;
      case Camur.job_type.setNodeChannelCount:
        name = "setNodeChannelCount";
        break;
      case Camur.job_type.discoverNodeChannelCount:
        name = "discoverNodeChannelCount";
        break;
      case Camur.job_type.setNodeTemperatureEnabled:
        name = "setNodeTemperatureEnabled";
        break;
      case Camur.job_type.discoverNodeTemperatureEnabled:
        name = "discoverNodeTemperatureEnabled";
        break;
      case Camur.job_type.discoverNodeAlarmLevels:
        name = "discoverNodeAlarmLevels";
        break;
      case Camur.job_type.discoverNodeScaleValues:
        name = "discoverNodeScaleValues";
        break;
      case Camur.job_type.discoverNodeResistanceParams:
        name = "discoverNodeResistanceParams";
        break;
      case Camur.job_type.setNodePreSampleInterval:
        name = "setNodePreSampleInterval";
        break;
      case Camur.job_type.discoverNodePreSampleInterval:
        name = "discoverNodePreSampleInterval";
        break;  
      case Camur.job_type.setNodeOutputInverted:
        name = "setNodeOutputInverted";
        break;
      case Camur.job_type.discoverNodeOutputInverted:
        name = "discoverNodeOutputInverted";
        break;  
      case Camur.job_type.setNodePowerInterface:
        name = "setNodePowerInterface";
        break;
      case Camur.job_type.setChannelAlarmLow:
        name = "setChannelAlarmLow";
        break;
      case Camur.job_type.setChannelAlarmHigh:
        name = "setChannelAlarmHigh";
        break;
      case Camur.job_type.setChannelAlarmEnabled:
        name = "setChannelAlarmEnabled";
        break;
      case Camur.job_type.resetChannelAlarm:
        name = "resetChannelAlarm";
        break;
      default:
        return this.txt.default_job_desc;
    }
    return name;
  }

  get_job_status(job) {
    let prefix = "";
    let suffix = job.succesful ? "_finished" : "_failed";
    // If not done, use active format
    if (!job.finished) suffix = "_active";
    prefix = this.get_job_type_name(job.type);

    let whole_name = prefix + suffix;
    return this.txt[whole_name];
  }

  get_enum_name(parent_object, index) {
    var property_name = "";

    for (var prop in parent_object) {
      if (parent_object[prop] == index) {
        property_name = prop;
        break;
      }
    }
    if (property_name == "") return null;

    if (this.txt.hasOwnProperty(property_name)) {
      return this.txt[property_name];
    }
    return null;
  }

  get_solution_for_error(error_code) {
    let key = "error_" + error_code + "_solutions";
    if (this.txt.hasOwnProperty(key)) {
      return this.txt[key];
    }
    return null;
  }

  get_explanation_for_error(error_code) {
    let key = "error_" + error_code + "_explanation";
    if (this.txt.hasOwnProperty(key)) {
      return this.txt[key];
    }
    return null;
  }

  get_text_for_error(error_code) {
    let key = "error_" + error_code + "_text";
    if (this.txt.hasOwnProperty(key)) {
      return this.txt[key];
    }
    return null;
  }

  get_anode_string(stringId) {
    if (stringId == null) return "";
    else {
      switch (stringId) {
        case "none": 
          return this.txt.anode_type_none; 
        case "zebra":
          return this.txt.anode_type_conductive_paint;
        case "surface_mounted_Ti":
          return this.txt.anode_type_surface_mounted_Ti;
        case "Ti_mesh":
          return this.txt.anode_type_Ti_mesh;
        case "Ti_ribbon":
          return this.txt.anode_type_Ti_ribbon;
        case "Ti_sawtooth":
          return this.txt.anode_type_Ti_sawtooth;
        case "discrete_anodes":
          return this.txt.anode_type_discrete_anodes;
        case "galvanic_anodes":
          return this.txt.anode_type_galvanic_anodes;
        case "anode_in_water":
          return this.txt.anode_type_in_water;
        case "multiple":
          return this.txt.anode_type_multiple;
        case "other":
          return this.txt.anode_type_other;
      }
    }
  }
  recording_to_string(rec) {
    if (rec == null) {
      return this.txt.rectype_all;
    }
    if (rec.id === Camur.all_recordings.id) {
      return this.txt.rectype_all;
    }
    var rectype =
      "(" +
      this.get_enum_name(
        Camur.recording_type,
        rec.dto.type
      ) +
      ")";
    var from = this.print_date_time(rec.start);
    var to =
      rec.stop === null ? "" : this.print_date_time(rec.stop);
    return rec.id + " - " + from + " - " + to + " " + rectype;
  }
}
