const Enum = require("@/utils/enums").default;
/*
This a collection of functions, enums and constants related to camur
*/

export default {
  supported_firmware: {
    ntype_none: { min: 1.0, max: 100.0 }, // None
    ntype_wlink: { min: 1.0, max: 100.0 }, // WLink
    ntype_lpr: { min: 0.0, max: 100.0 }, // LPR
    ntype_power_interface: { min: 1.0, max: 100.0 }, // Power Interface
    ntype_io: { min: 1.0, max: 100.0 }, // I/O
    ntype_alarm: { min: 1.0, max: 100.0 }, // Alarm
    ntype_pquick: { min: 0.0, max: 100.0 }, // P-Quick
    ntype_hum: { min: 1.0, max: 100.0 }, // HUM
    ntype_hires: { min: 1.0, max: 100.0 }, // HiRes
    ntype_resmes: { min: 1.0, max: 100.0 }, // ResMes
    ntype_zra: { min: 1.0, max: 100.0 }, // ZRA
    ntype_ct: { min: 0.0, max: 100.0 }, // CT
    ntype_mre: { min: 1.0, max: 100.0 }, // MRE
    ntype_corrowatch: { min: 1.0, max: 100.0 }, // CorroWatch
    ntype_p4: { min: 0.0, max: 100.0 }, // Px4
    ntype_ladder: { min: 1.0, max: 100.0 }, // Anode Ladder
    ntype_crackwatch: { min: 1.0, max: 100.0 }, // CrackWatch
    ntype_pt: { min: 1.0, max: 100.0 }, // P+T
    ntype_wenner: { min: 1.0, max: 100.0 }, // Wenner
    ntype_corr_sense: { min: 1.0, max: 100.0 }, // CorrSense
    ntype_p4i: { min: 0.0, max: 100.0 }, // P4i
    ntype_fv8: { min: 0.0, max: 100.0 }, // Fixvolt 8
    ntype_fv3: { min: 0.0, max: 100.0 }, // Fixvolt 3
    ntype_fv01: { min: 1.0, max: 100.0 }, // Fixvolt 0,1A
    ntype_fv1a: { min: 1.0, max: 100.0 } // Fixvolt 1A
  },

  controller_state: {
    state_listening_to_commands: 200,
    state_connected: 202,
    state_discovering: 205,
    state_repairing: 206,
    state_controller_busy: 207,
    state_disconnected: 400,
    state_not_connected: 410,
    state_uninitialized: 425,
    state_no_assigned_device: 426,
    state_initialize: 428,
    state_failure: 500,
    state_discover_time_stamp: 1001,
    state_discover_version: 1002,
    state_discover_ip: 1003,
    state_discover_ping_interval: 1004,
    state_discover_monitor: 1005,
    state_discover_decay: 1006,
    state_discover_lpr: 1007,
    state_discover_resmes: 1008,
    state_discover_zra: 1009,
    state_discover_schedule: 1010,
    state_discover_recording: 1011,
    state_discover_node_info: 1012,
    state_discover_ack_timestamp: 1013,
    state_discover_done: 1099
  },

  get_node_version_supported(node) {
    var version = node.version_float;
    var node_string = "None";

    for (var prop in this.node_type) {
      if (this.node_type[prop] == node.capability) {
        node_string = prop;
        break;
      }
    }
    var min = this.supported_firmware[node_string].min;
    var max = this.supported_firmware[node_string].max;
    return version >= min && version <= max;
  },

  get_series_color_scheme() {
    return [
      "red",
      "orange",
      "blue",
      "green",
      "LightSeaGreen",
      "purple",
      "dimgray",
      "brown",
      "black",
      "olive",
      "teal",
      "#ebac23",
      "#b80058",
      "#008cf9",
      "#006e00",
      "#00bbad",
      "#d163e6",
      "#b24502",
      "#ff9287",
      "#5954d6",
      "#00c6f8",
      "#878500",
      "#00a76c",
    ];
  },

  is_output_channel(channel_object) {
    return channel_object.hasOwnProperty("senseGuardEnabled");
  },

  node_function_is_supported(capability) {
    switch (capability) {
      case this.node_type.ntype_corr_sense:
      case this.node_type.ntype_ladder:
      case this.node_type.ntype_hum:
      case this.node_type.ntype_power_interface:
 
      case this.node_type.ntype_lpr:
      case this.node_type.ntype_mre:
      case this.node_type.ntype_corrowatch:
      case this.node_type.ntype_p4:
      case this.node_type.ntype_p4i:  
      case this.node_type.ntype_resmes:
      case this.node_type.ntype_wenner:
      case this.node_type.ntype_zra:
      case this.node_type.ntype_pt:
      case this.node_type.ntype_fv3:
      case this.node_type.ntype_fv8:
      case this.node_type.ntype_fv01:
      case this.node_type.ntype_fv1a:
      case this.node_type.ntype_pquick: {
        return true;
      }
      default: {
        return false;
      }
    }
  },

  is_adv_node(node_type) {
    switch (node_type) {
      case this.node_type.ntype_corr_sense:
      case this.node_type.ntype_ladder:
      case this.node_type.ntype_hum:

      case this.node_type.ntype_lpr:
      case this.node_type.ntype_hum:
      case this.node_type.ntype_resmes:
      case this.node_type.ntype_wenner:  
      case this.node_type.ntype_zra:
      case this.node_type.ntype_mre:
      case this.node_type.ntype_ladder:
      case this.node_type.ntype_crackwatch:
      case this.node_type.ntype_pt:
      case this.node_type.ntype_wenner:
      case this.node_type.ntype_hires:
      case this.node_type.ntype_corrowatch:
      case this.node_type.ntype_p4: 
      case this.node_type.ntype_p4i:{
        return true;
      }
      default: {
        return false;
      }
    }
  },

  is_power_supply(node_type) {
    switch (node_type) {
      case this.node_type.ntype_power_interface:
      case this.node_type.ntype_fv3:
      case this.node_type.ntype_fv8:
      case this.node_type.ntype_fv01:
      case this.node_type.ntype_fv1a: {
        return true;
      }
      default: {
        return false;
      }
    }
  },

  is_p_node(node_type) {
    switch (node_type) {
      case this.node_type.ntype_p4:
      case this.node_type.ntype_p4i:
      case this.node_type.ntype_pquick:
      case this.node_type.ntype_pt: {
        return true;
      }
      default: {
        return false;
      }
    }
  },

  is_radio_link(node_type) {
    switch (node_type) {
      case this.node_type.ntype_wlink: {
        return true;
      }
      default: {
        return false;
      }
    }
  },

  ok_nok_color(val) {
    return val ? "gray" : "red";
  },

  good_bad_color(val) {
    return val ? "green" : "red";
  },

  connected_color(val) {
    return val ? "blue" : "yellow";
  },

  active_inactive_color(val) {
    return val ? "green" : "gray";
  },

  color_code_standby() {
    return "rgba(222, 33, 0, 1)";
  },

  color_code_monitor() {
    return "rgba(50, 144, 255, 1)";
  },

  color_code_lpr() {
    return "rgba(0, 186, 125, 1)";
  },

  color_code_decay() {
    return "rgba(15, 32, 143, 1)";
  },

  color_code_zra() {
    return "rgba(15, 100, 50, 1)";;
  },
  
  color_code_resmes() {
    return "rgba(15, 100, 50, 1)";
  },

  color_code_default() {
    return "orange";
  },

  color_code_status_of_rectype(rectype) {
    switch (rectype) {
      case this.recording_type.rectype_standby: {
        return this.color_code_standby();
      }

      case this.recording_type.rectype_monitor: {
        return this.color_code_monitor();
      }

      case this.recording_type.rectype_lpr: {
        return this.color_code_lpr();
      }

      case this.recording_type.rectype_decay: {
        return this.color_code_decay();
      }

      case this.recording_type.rectype_zra: {
        return this.color_code_zra();
      }

      case this.recording_type.rectype_resmes: {
        return this.color_code_resmes();
      }

      default: {
        return this.color_code_default();
      }
    }
  },

  recording_types_for_report_type(reptype) {
    var result = [];

    var include_monitor = [
      this.report_type.reptype_all_recordings,
      this.report_type.reptype_monitor,
      this.report_type.reptype_monitor_decay
    ];
    if (include_monitor.includes(reptype)) {
      result.push(this.recording_type.rectype_monitor);
    }

    var include_lpr = [
      this.report_type.reptype_all_recordings,
      this.report_type.reptype_lpr_measurements
    ];
    if (include_lpr.includes(reptype)) {
      result.push(this.recording_type.rectype_lpr);
    }

    var include_lpr_results = [
      this.report_type.reptype_lpr_results
    ];
    if (include_lpr_results.includes(reptype)) {
      result.push(this.recording_type.rectype_lpr_results);
    }

    var include_decay = [
      this.report_type.reptype_all_recordings,
      this.report_type.reptype_decay,
      this.report_type.reptype_monitor_decay
    ];
    if (include_decay.includes(reptype)) {
      result.push(this.recording_type.rectype_decay);
    }

    var include_decay_result = [
      this.report_type.reptype_decay_results,
    ];
    if (include_decay_result.includes(reptype)) {
      result.push(this.recording_type.rectype_decay_results);
    }

    var include_zra = [
      this.report_type.reptype_all_recordings,
      this.report_type.reptype_zra
    ];
    if (include_zra.includes(reptype)) {
      result.push(this.recording_type.rectype_zra);
    }

    var include_resmes = [
      this.report_type.reptype_all_recordings,
      this.report_type.reptype_resmes
    ];
    if (include_resmes.includes(reptype)) {
      result.push(this.recording_type.rectype_resmes);
    }

    return result;
  },

  channel_name_short(channel) {
    var in_out = channel.isOutput ? "IN" : "OUT";
    return "V-" + in_out + "#" + channel.locals.no;
  },

  all_recordings: {
    id: -1
  },

  node_type: {
    ntype_none: 0, // None
    ntype_wlink: 9, // WLink
    ntype_lpr: 10, // LPR
    ntype_power_interface: 11, // Power Interface
    ntype_io: 12, // I/O
    ntype_alarm: 13, // Alarm
    ntype_pquick: 14, // P-Quick
    ntype_hum: 15, // HUM
    ntype_hires: 16, // HiRes
    ntype_resmes: 17, // ResMes
    ntype_zra: 18, // ZRA
    ntype_ct: 21, // CT
    ntype_mre: 22, // MRE
    ntype_corrowatch: 23, // CorroWatch
    ntype_p4: 24, // Px4
    ntype_ladder: 25, // Anode Ladder
    ntype_crackwatch: 26, // CrackWatch
    ntype_pt: 27, // P+T
    ntype_wenner: 28, // Wenner
    ntype_corr_sense: 29, // CorrSense
    ntype_p4i: 30, // P4i
    ntype_fv8: 31, // Fixvolt 8
    ntype_fv3: 32, // Fixvolt 3
    ntype_fv01: 33, // Fixvolt 0,1A
    ntype_fv1a: 34 // Fixvolt 1A
  },

  job_type: {
    getControllerStatus: 20900001,
    getControllerLog: 20900002,

    recordingModeChange: 20010001,
    getControllerRecordingData: 20010100,

    setControllerRecordingSettings: 21010200,

    setControllerRecordingDecayParams: 21011100,
    setControllerRecordingLprParams: 21011200,
    setControllerRecordingMonitorParams: 21011300,
    setControllerRecordingScheduleParams: 21011400,
    setControllerRecordingZraParams: 21011500,
    setControllerRecordingResMesParams: 21011600,
    setControllerSwitchOffOnAlarm: 21011700, 

    discoverControllerMonitorParams: 20011101,
    discoverControllerDecayParams: 20011201,
    discoverControllerLprParams: 20011301,

    discoverControllerResMesParams: 20012301,
    discoverControllerZraParams: 20012101,
    discoverControllerSwitchOffOnAlarm: 20012401,

    discoverControllerScheduleParams: 21011401,
    discoverControllerAckTimestamp: 21011501,
    discoverControllerNodes: 21011601,
    discoverControllerRecordingStatus: 21011701,
    discoverControllerTimestamp: 21011801,
    discoverControllerVersion: 21011901,

    upgradeControllerFirmware: 20101001,
    controllerReboot: 20101101,
    controllerPrepareBoot: 20101102,
    controllerCancelGetRecordingData: 20101103,
    controllerPingPeriodReset:20101104, 
    controllerResetCAN:20101105,

    setNodePowerSupplyParams: 30010000,
    setFixVoltOutputMode: 30010001,
    setFixVoltOutputOnOff: 30010002,
    setFixVoltOutputRecovery: 30010003,
    setFixVoltOutputVoltage: 30010004,
    setFixVoltOutputCurrent: 30010005,
    setFixVoltSwitchOffOnAlarm: 30010006,

    discoverNodePowerSupplyParams: 30010201,

    setAlarmStatus: 30020010,

    setNodeCurrTransParams: 30030001,
    discoverNodeCurrTransParams: 30030201,

    setNodeChannelCount: 30040001,
    discoverNodeChannelCount: 30040101,

    setNodeTemperatureEnabled: 30050001,
    discoverNodeTemperatureEnabled: 30050101,

    discoverNodeScaleValues: 30060001,
    discoverNodeAlarmLevels: 30060002,

    discoverNodeResistanceParams: 30070001,

    setNodePreSampleInterval: 30080001,
    discoverNodePreSampleInterval: 30080101,

    setNodeOutputInverted: 30090001,
    discoverNodeOutputInverted: 30090101,
    setNodePowerInterface: 30090201,

    setChannelAlarmLow: 40010001,
    setChannelAlarmHigh: 40010002,
    setChannelAlarmEnabled: 40010100,
    resetChannelAlarm: 40010200
  },

  job_status: {
    queued: 100,
    inProgress: 101,
    success: 200,
    failure: 500
  },

  io_type: {
    io_type_input: 1, // Input channel
    io_type_output: 2 // Output channel
  },

  anode_type: {
    anode_type_none: 0,
    anode_type_conductive_paint:1,
    anode_type_surface_mounted_Ti:2,
    anode_type_Ti_mesh:3,
    anode_type_Ti_ribbon:4,
    anode_type_Ti_sawtooth:5,
    anode_type_discrete_anodes:6,
    anode_type_galvanic_anodes:7,
    anode_type_in_water:8,
    anode_type_multiple:9,
    anode_type_other:10
  },

  recording_type: {
    rectype_standby: 0,
    rectype_monitor: 1,
    rectype_lpr: 2,
    rectype_decay: 3,
    rectype_zra: 6,
    rectype_resmes: 7,
    rectype_lpr_results: 2001,
    rectype_decay_results: 2005,

    // Future or obsolete ??
    /*rectype_monext: 4,
    rectype_scheduled_values: 5,
    rectype_ctnonstat: 10,
    rectype_ctstat: 11,
    rectype_rext: 12 */
  },

  get txt() {
    return app.$txt;
  },

  get_event_type_text(event_type) {
    switch (event_type) {
      case Enum.event_types.general:
        return this.txt.general;
      case Enum.event_types.system_start:
        return this.txt.system_start;
      case Enum.event_types.alarm:
        return this.txt.alarm;
      case Enum.event_types.client_com:
        return this.txt.client_com;
      case Enum.event_types.can_com:
        return this.txt.can_com;
      default:
        return this.txt.unknown;
    }
  },

  get_rectype_text(rectype) {
    switch (rectype) {
      case this.recording_type.rectype_standby:
        return this.txt.rectype_standby;
      case this.recording_type.rectype_monitor:
        return this.txt.rectype_monitor;
      case this.recording_type.rectype_lpr:
        return this.txt.rectype_lpr;
      case this.recording_type.rectype_decay:
        return this.txt.rectype_decay;
      case this.recording_type.rectype_zra:
        return this.txt.rectype_zra;
      case this.recording_type.rectype_resmes:
        return this.txt.rectype_resmes;
      case this.recording_type.rectype_lpr_results:
        return this.txt.rectype_lpr_results;  
      case this.recording_type.rectype_decay_results:
        return this.txt.rectype_decay_results;        

      /*         Future or obsolete ??
      case this.recording_type.rectype_monext:
        return this.txt.rectype_monext;
      case this.recording_type.rectype_scheduled_values:
        return this.txt.rectype_scheduled_values;
      case this.recording_type.rectype_ctnonstat:
        return this.txt.rectype_ctnonstat;
      case this.recording_type.rectype_ctstat:
        return this.txt.rectype_ctstat;
      case this.recording_type.rectype_rext:
        return this.txt.rectype_rext;
 */
      default:
        return this.txt.rectype_unknown;
    }
  },

  get_rectype_of(report_type) {
    switch (report_type) {
      case this.report_type.reptype_monitor:
        return this.recording_type.rectype_monitor;
      case this.report_type.reptype_lpr_measurements:
        return this.recording_type.rectype_lpr;
      case this.report_type.reptype_decay:
        return this.recording_type.rectype_decay;
      case this.report_type.reptype_zra:
        return this.recording_type.rectype_zra;
      case this.report_type.reptype_resmes:
        return this.recording_type.rectype_resmes;
      case this.report_type.reptype_lpr_results:
        return this.recording_type.rectype_lpr_results;
      case this.report_type.reptype_decay_results:
        return this.recording_type.rectype_decay_results;        

      default:
        return this.recording_type.rectype_standby;
    }
  },

  channelUsed(channelNo, capability, channelCount, logTemperature) {
        // channel.no is 0-based
    let chNo = channelNo + 1;
    let channelUsed = false;

    switch (capability) 
    {
      case this.node_type.ntype_wenner:
        // Only R-channel used by WebMonitor
        // T-channel (2) created by server but not used for Wenner
        if (chNo == 1)
          channelUsed = true;
        else
          channelUsed = false;
        break;
      case this.node_type.ntype_mre:
        // MRE has channelCount fixed as 6 and only R-channels
        // are actually used
        // Channel 1-15  : Not used
        // Channel 16-22 : R1-R7
        // Channel 23    : T

        // channel.no is 0-based

        // R-channels
        if (chNo >= 16 && chNo <= 22) 
          channelUsed = true;
        else if (chNo == 23 && logTemperature) 
          channelUsed = true;
        break;

      case this.node_type.ntype_corrowatch:
      case this.node_type.ntype_ladder:
        // CorroWatch has channelCount to define what channels
        // are actually used
        // Channel 1-7   : U1-U7
        // Channel 8     : Not used
        // Channel 9-15  : I1-I7
        // Channel 16-21 : R1-R6
        // Channel 23    : T
        // Channel 25-31 : Corr1-Corr7
        // Channel 32-38 : Sigma1-Sigma7


        if (chNo >= 1 && chNo < 1 + channelCount) channelUsed = true;
        else if (chNo >= 9 && chNo < 9 + channelCount) channelUsed = true;
        // R-channels count is one less than U and I
        else if (chNo >= 16 && chNo < 16 - 1 + channelCount) channelUsed = true;
        else if (chNo == 23 && logTemperature) channelUsed = true;
        else if (chNo >= 25 && chNo < 25 + channelCount) channelUsed = true;
        else if (chNo >= 32 && chNo < 32 + channelCount) channelUsed = true;
        break;

      default: 
        channelUsed = true; 
        break;
    }
    return channelUsed;
  },

  channelUsedAsLiveValue(channelNo, capability, channelCount, logTemperature, ctMode, recordingMode) {
    // channel.no is 0-based
    let chNo = channelNo + 1;
    let channelUsed = false;

    switch (capability) 
    {
      case this.node_type.ntype_resmes:
      case this.node_type.ntype_corr_sense:
      case this.node_type.ntype_zra:
      case this.node_type.ntype_pt:
        if (chNo == 1) 
          channelUsed = true;
        else if (chNo == 2 && logTemperature) 
          channelUsed = true;
        break;
      case this.node_type.ntype_lpr:
        if ((chNo == 1) || (chNo == 2))
          channelUsed = true;
        else if (chNo == 3 && logTemperature) 
          channelUsed = true;        
        break;
      case this.node_type.ntype_fv3:
      case this.node_type.ntype_fv8:
        if ((chNo >= 1) && (chNo <= 4))
          channelUsed = true;
        else if (chNo == 5 && logTemperature) 
          channelUsed = true;    
        break;
      case this.node_type.ntype_wenner:
        // Only R-channel used by WebMonitor
        // T-channel (2) created by server but not used for Wenner
        if (chNo == 1)
          channelUsed = true;
        else
          channelUsed = false;
        break;
      case this.node_type.ntype_mre:
        // MRE has channelCount fixed as 6 and only R-channels
        // are actually used
        // Channel 1-15  : Not used
        // Channel 16-22 : R1-R7
        // Channel 23    : T

        // channel.no is 0-based

        // R-channels
        if (chNo >= 16 && chNo <= 22) 
          channelUsed = true;
        else if (chNo == 23 && logTemperature) 
          channelUsed = true;
        break;

      case this.node_type.ntype_corrowatch:
      case this.node_type.ntype_ladder:
        // CorroWatch has channelCount to define what channels
        // are actually used
        // Channel 1-7   : U1-U7
        // Channel 8     : Not used
        // Channel 9-15  : I1-I7
        // Channel 16-21 : R1-R6
        // Channel 23    : T
        // Channel 25-31 : Corr1-Corr7
        // Channel 32-38 : Sigma1-Sigma7
/*
        recording_type: {
          rectype_standby: 0,
          rectype_monitor: 1,
          rectype_lpr: 2,
          rectype_decay: 3,
          rectype_zra: 6,
          rectype_resmes: 7,
          rectype_lpr_results: 2001,
          rectype_decay_results: 2005,*/

        if (chNo >= 1 && chNo < 1 + channelCount) {
          if ((recordingMode == this.recording_type.rectype_standby) && (ctMode == this.ct_mode.ct_mode_p)) {
            channelUsed = true;
          } else if ((recordingMode == this.recording_type.rectype_monitor) || (recordingMode == this.recording_type.rectype_decay)) {
            channelUsed = true;
          }
        }
        else if (chNo >= 9 && chNo < 9 + channelCount) {
          if ((recordingMode == this.recording_type.rectype_standby) && (ctMode == this.ct_mode.ct_mode_zra)) {
            channelUsed = true;  
          } else if (recordingMode == this.recording_type.rectype_zra) {
            channelUsed = true;
          }
        }
        // R-channels count is one less than U and I
        else if (chNo >= 16 && chNo < 16 - 1 + channelCount) {
          if ((recordingMode == this.recording_type.rectype_standby) && (ctMode == this.ct_mode.ct_mode_r)) {
            channelUsed = true;  
          } else if (recordingMode == this.recording_type.rectype_resmes) {
            // channelUsed = true;
            // U / I reported as live-values here, but makes no sense to show?
            channelUsed = false;
          }
        }
        else if (chNo == 23 && logTemperature) 
          channelUsed = true;
        break;

      default: 
        channelUsed = true; 
        break;
    }
    return channelUsed;
  },


  report_type: {
    reptype_all_recordings: 0,
    reptype_monitor: 1,
    reptype_lpr_measurements: 2,
    reptype_decay: 3,
    reptype_zra: 4,
    reptype_resmes: 5,
    reptype_separator: 100,
    reptype_lpr_results: 6,
    reptype_decay_results: 7
  },

  power_supply_mode: {
    ps_mode_current: 0,
    ps_mode_voltage: 1
  },
  power_interface_ps_mode: {
    current: 0,
    voltage: 1
  },
  power_interface_control_mode: {
    //pi_mode_unknown: 0,
    pi_mode_current: 1,
    pi_mode_voltage: 2
  },
  current_range: {
    current_range_low: 0,
    current_range_high: 1
  },
  ct_mode: {
    ct_mode_r: 0,
    ct_mode_zra: 1,
    ct_mode_p: 2
  },
  ct_zra_range: {
  //  ct_zra_range_auto: 0,
    ct_zra_range_5mA: 1,
    ct_zra_range_350µA: 2,
    ct_zra_range_50µA: 3,
  //  ct_zra_range_10µA: 4
  },
  ct_corr_sense_range: {
      ct_corr_sense_range_2k: 1,
      ct_corr_sense_range_150: 2,
      ct_corr_sense_range_20: 3,
      ct_corr_sense_range_4: 4
    },
  ct_resmes_range: {
    ct_resmes_range_auto: 0,
    ct_resmes_range_r1: 1,
    ct_resmes_range_r2: 2,
    ct_resmes_range_r3: 3,
    ct_resmes_range_r4: 4
  },
  ct_resmes_freq: {
    ct_resmes_freq_100Hz: 0,
    ct_resmes_freq_1kHz: 1
  },
  select_mode_level: {
    project:0,
    controller:1
  }
};
