/*
Note: To ensure that enums can be used nicely in comboboxes,
I rely on the function get_enum_name() in utils/langutils.js. For this to
work, there must be a key in the language file for each entrance
in the enum, for example a key named "reptype_all_recordings".
This also means that no to enum values can have the same value.
Because JS technically does not have enums I have solved it this
way by creating objects and manually assign values to them.
*/

export default {
  report_intervals: {
    this_year: 0,
    this_month: 1,
    this_week: 2,
    today: 3,
    from_to: 4,
    all_intervals: 5
  },

  //1=SystemStart 2=Alarm 3=ClientCom 4=CANCom
  // Enum translated to language-text
  event_types: {
    all_types: 0,
    general: 200001000,
    system_start: 200001001,
    alarm: 200001002,
    client_com: 200001003,
    can_com: 200001004
  },

  // Severity-levels of event
  // Enum translated to language-text
  event_levels: {
    all_levels: 0,
    event_level_fatal: 1,
    event_level_error: 2,
    event_level_warning: 3,
    event_level_information: 4,
    event_level_debug: 5,
    //event_level_trace: 6
  },

  // Possible options when setting the 
  // storage log-level
  log_event_levels: {
    log_event_level_information: 4,
    log_event_level_debug: 5
  },

  project_mode: {
    cathodic_protection: 0,
    monitoring: 1
  },

  lpr_mode: {
    lpr_mode_standard: 1
  },

  schedule_unit: {
    schedule_unit_minute: 0,
    schedule_unit_hour: 1,
    schedule_unit_day: 2,
    schedule_unit_month: 3
  },
  interval_unit: {
    interval_unit_second: 1,
    interval_unit_minute: 2,
    interval_unit_hour: 3
  },
  monitor_interval_unit: {
    monitor_interval_unit_second: 1,
    monitor_interval_unit_minute: 2,
    monitor_interval_unit_hour: 3,
    monitor_interval_unit_day: 4
  },  
  graph_pos: {
    graph_pos_bottom:0,
    graph_pos_high:1,
    graph_pos_top:2
  },
  graph_type: {
    graph_type_line:0,
    graph_type_bar:1
  },
  controller_event_code:{
    log_clear:0,
    log_ctrlboot:1,
    log_dhcp_fail:2,
    log_dhcp_renew:3,
    log_servercheck:4,
    log_recordingstart:5,
    log_recordingstop:6,
    log_recrestart:7,
    log_ntpset:8,
    log_firmware:9,
    log_ethboot:10,
    log_firmwarefail:11,
    log_nodelost:12,
    log_can_rx_overflow:13,
    log_can_rx_invalid_msg:14,
    log_can_tx_full:15,
    log_can_reset:16,
    log_node_add:17,
    log_debug_index:18,
    log_linerr:19,
    log_ntperr:20,
    log_rtctime:21
  }
};
