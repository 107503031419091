 
import { createRouter, createWebHashHistory } from "vue-router";
import SuccessPage from "@/components/special_views/SuccessPage.vue";
import LoginView from "@/components/LoginView";
import ProjectManagerView from "@/components/prjmanager/ProjectManagerView";
import ProjectBaseView from "@/components/ProjectBaseView";
// Lazy-load the rest of the components to reduce bundle-size and improve performance
const PageNotFoundPage = () => import('@/components/errors/PageNotFound.vue');
const AboutPage = () => import('@/components/special_views/AboutPage.vue');
const ErrorLearnMoreView = () => import('@/components/special_views/ErrorLearnMoreView.vue');
const UnavailablePage = () => import('@/components/errors/UnavailablePage.vue');
const UsersView = () => import('@/components/UsersView.vue');
const CustomersView = () => import('@/components/CustomersView.vue');
const ResetPasswordView = () => import('@/components/special_views/ResetPasswordView.vue');
const VerifyUserView = () => import('@/components/special_views/VerifyUserView.vue');
const AppSettingsView = () => import('@/components/settings/AppSettingsView.vue');
const AccountView = () => import('@/components/AccountView.vue');
const Accounting = () => import('@/components/settings/AdminAccountingView.vue');
const AdminView = () => import('@/components/settings/Admin.vue');
const SettingsView = () => import('@/components/settings/SettingsView.vue');
const UserSettingsView = () => import('@/components/settings/UserSettingsView.vue');
const AdminNewProjectView = () => import('@/components/admin/AdminNewProjectView.vue');
const AdminNewCustomerView = () => import('@/components/admin/AdminNewCustomerView.vue');
const AdminNewUserView = () => import('@/components/admin/AdminNewUserView.vue');
const ZoneOverviewTab = () => import('@/components/zone/ZoneOverviewTab.vue');
const ZoneInfoTab = () => import('@/components/zone/ZoneInfoTab.vue');
const ReportTab = () => import('@/components/zone/ReportTab.vue');
const LiveValuesTab = () => import('@/components/zone/LiveValuesTab.vue');
const ZoneView = () => import('@/components/zone/ZoneView.vue');
const ProjectView = () => import('@/components/project/ProjectView.vue');
const OverviewTab = () => import('@/components/project/OverviewTab.vue');
const ProjectInfoTab = () => import('@/components/project/ProjectInfoTab.vue');
const ProjectLiveValues = () => import('@/components/project/ProjectLiveValues.vue');
const AddScaffolding = () => import('@/components/project/AddScaffolding.vue');
const CntrlTable = () => import('@/components/project/CntrlTable.vue');
const AccessTab = () => import('@/components/project/AccessTab.vue');
const EventsTab = () => import('@/components/project/EventsTab.vue');
const NotifyTab = () => import('@/components/project/NotifyTab.vue');
const ServiceTab = () => import('@/components/project/ServiceTab.vue');
const ProjectReportTab = () => import('@/components/project/ProjectReportTab.vue');
const ControllerView = () => import('@/components/cntrl/CntrlView.vue');
const CntrlOverviewTab = () => import('@/components/cntrl/CntrlOverviewTab.vue');
const CntrlConfigTab = () => import('@/components/cntrl/CntrlConfigTab.vue');
const CntrlReportTab = () => import('@/components/cntrl/CntrlReportTab.vue');
const CntrlDeviceTab = () => import('@/components/cntrl/CntrlDevicesTab.vue');
const NodeView = () => import('@/components/node/NodeView.vue');
const NodeOverviewTab = () => import('@/components/node/NodeOverviewTab.vue');
const NodeConfigTab = () => import('@/components/node/NodeConfigTab.vue');
const NodeLiveValues = () => import('@/components/node/NodeLiveValues.vue');
const CustomerView = () => import('@/components/customer/CustomerView.vue');
const ConfigMultipleNodes = () => import('@/components/project/ConfigMultipleNodes.vue');




const route_start = {
  path: "/",
  name: "start",
  redirect: "/projects"
};

const route_page_not_found = {
  path: "/notfound",
  name: "page_not_found",
  meta: { no_login: true },

  component: PageNotFoundPage
};

const route_no_match = {
  path: "/.*",
  redirect: "/notfound",
  meta: { no_login: true }
};

const route_success = {
  path: "/success/:type",
  name: "success",
  meta: { no_login: true },
  component: SuccessPage
};

const route_about = {
  path: "/about",
  name: "about_camur",
  meta: { no_login: true },
  component: AboutPage
};

const route_unavailable = {
  path: "/unavailable",
  name: "unavailable",
  meta: { no_login: true },
  component: UnavailablePage
};

const route_project_manager = {
  path: "/projects",
  name: "project_manager",
  component: ProjectManagerView
};

const route_users = {
  path: "/users",
  name: "users",
  component: UsersView
};

const route_customers = {
  path: "/customers",
  name: "customers",
  component: CustomersView
};

const route_login = {
  path: "/login",
  name: "login",
  meta: { no_login: true },
  component: LoginView
};

const route_reset = {
  path: "/reset/:id/:token",
  name: "reset",
  meta: { no_login: true },
  component: ResetPasswordView
};

const route_verify_user = {
  path: "/verify/:id/:token",
  name: "verify",
  meta: { no_login: true },
  component: VerifyUserView
};

const route_appsettings = {
  path: "app",
  name: "app_settings",
  component: AppSettingsView
};

const route_account = {
  path: "account",
  name: "account",
  component: AccountView,
  children: []
};

const route_accounting = {
  path: "accounting",
  name: "accounting",
  component: Accounting
};

const route_admin = {
  path: "admin",
  name: "admin",
  component: AdminView,
  children: []
};

const route_settings = {
  path: "/settings",
  name: "settings",
  component: SettingsView,
  children: [route_appsettings, route_admin, route_account]
};

const route_usersettings = {
  path: "/user/:user_id",
  name: "usersettings",
  component: UserSettingsView
};

const route_admin_newproject = {
  path: "/admin/newproject",
  name: "newproject",
  component: AdminNewProjectView
};

const route_admin_newcustomer = {
  path: "/admin/newcustomer",
  name: "newcustomer",
  component: AdminNewCustomerView
};

const route_admin_newuser = {
  path: "/newuser",
  name: "admin_newuser",
  component: AdminNewUserView
};

// Project
const route_project_overview = {
  path: "overview",
  name: "project_overview",
  component: OverviewTab,
};
const route_project_config = {
  path: "config",
  name: "project_config",
  component: ProjectInfoTab,
};
const route_project_live_values = {
  path: "livevalues",
  name: "project_live_values",
  component: ProjectLiveValues
};
const route_project_holders = {
  path: "holders",
  name: "project_holders",
  component: AddScaffolding
};
const route_project_devices = {
  path: "devices",
  name: "project_devices",
  component: CntrlTable
};
const route_project_access = {
  path: "access",
  name: "project_access",
  component: AccessTab
};
const route_project_events = {
  path: "events",
  name: "project_events",
  component: EventsTab
};
const route_project_notify = {
  path: "notify",
  name: "project_notify",
  component: NotifyTab
};
const route_project_service = {
  path: "service",
  name: "project_service",
  component: ServiceTab
};
const route_project_report = {
  path: "report",
  name: "project_report",
  component: ProjectReportTab
};

const route_config_multiple_nodes = {
  path: 'configmultiplenodes',
  name: 'config_multiple_nodes',
  component: ConfigMultipleNodes,
};

const route_project = {
  path: ":project_id",
  name: "project",
  component: ProjectView,
  children: [route_project_overview, 
            route_project_config, 
            route_project_live_values, 
            route_project_holders,
            route_project_devices,
            route_project_access,
            route_project_events,
            route_project_notify,
            route_project_service,
            route_project_report,
            route_config_multiple_nodes
          ]
};
const route_controller_overview = {
  path: "overview",
  name: "controller_overview",
  component: CntrlOverviewTab
};
const route_controller_config = {
  path: "config",
  name: "controller_config",
  component: CntrlConfigTab
};
const route_controller_report = {
  path: "report",
  name: "controller_report",
  component: CntrlReportTab
};
const route_controller_holders = {
  path: "holders",
  name: "controller_holders",
  component: AddScaffolding
};
const route_controller_devices = {
  path: "devices",
  name: "controller_devices",
  component: CntrlDeviceTab
};

const route_controller = {
  path: ":project_id/controller/:controller_id",
  name: "controller",
  component: ControllerView,
  children: [
    route_controller_overview,
    route_controller_config,
    route_controller_report,
    route_controller_holders,
    route_controller_devices
  ]
};
// Zone Tabs

const route_zone_overview = {
  path: "overview",
  name: "zone_overview",
  component: ZoneOverviewTab
};


const route_zone_config = {
  path: "config",
  name: "zone_config",
  component: ZoneInfoTab
};

const route_zone_report = {
  path: "report",
  name: "zone_report",
  component: ReportTab
};

const route_zone_live_values = {
  path: "livevalues",
  name: "zone_live_values",
  component: LiveValuesTab
};

const route_zone_holders = {
  path: "holders",
  name: "zone_holders",
  component: AddScaffolding
};
const route_zone_devices = {
  path: "devices",
  name: "zone_devices",
  component: CntrlDeviceTab
};

const route_zone = {
  path: ":project_id/zone/:zone_id",
  name: "zone",
  component: ZoneView,
  children: [
    route_zone_overview,
    route_zone_config,
    route_zone_report,
    route_zone_live_values,
    route_zone_holders,
    route_zone_devices
  ]
};

const route_node_overview = {
  path: "overview",
  name: "node_overview",
  component: NodeOverviewTab
};

const route_node_config = {
  path: "config",
  name: "node_config",
  component: NodeConfigTab
};

const route_node_live_values = {
  path: "livevalues",
  name: "node_live_values",
  component: NodeLiveValues
};

const route_node_devices = {
  path: "devices",
  name: "node_devices",
  component: CntrlDeviceTab
};

const route_node = {
  path: ":project_id/zone/:zone_id/node/:node_id",
  name: "node",
  component: NodeView,
  children: [
    route_node_overview,
    route_node_config,
    route_node_live_values,
    route_node_devices
  ]
};

const route_project_base = {
  path: "/prj",
  name: "project_base",
  component: ProjectBaseView,
  children: [route_project, route_zone, route_node, route_controller]
};

const route_customer = {
  path: "/customer/:customer_id",
  name: "customer",
  component: CustomerView
};

const route_errorlearnmore = {
  path: "/errorlearnmore/:errorCode",
  name: "error_learn_more",
  meta: { no_login: true },
  component: ErrorLearnMoreView,
  children: []
};

const routes = [
  route_admin_newcustomer,
  route_admin_newproject,
  route_admin_newuser,
  route_project_base,
  route_settings,
  route_customer,
  route_customers,
  route_login,
  route_reset,
  route_verify_user,
  route_project_manager,
  route_start,
  route_usersettings,
  route_users,
  route_success,
  route_errorlearnmore,
  route_unavailable,
  route_page_not_found,
  route_about,

  route_no_match // This must be last!
];
  


const router = createRouter({
  routes,
  //history: createWebHistory()
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {
  if (global.app != null) {
    // Abort navigation if halted by user or other reason
    if (global.app.$appm.before_new_route(to, from, next) == false) return;
  }
  next();
});

export default router;
