<template lang="html">
  <PNGIcon
    :icon="icon"
    :title="hint"
    :alarm="alarm"
    :alarm_acknowledged="alarm_acked"
  />
</template>

<script>
export default {
  name: "SmartChannelIcon",

  props: {
    node: Object,
    channel: Object
  },

  computed: {
    icon() {
      if (this.node && this.node.capability) {
        return this.$appm.get_icon_for_node_type(this.node.capability);

      } return 'default-icon';
    },

    alarm() {
      return this.channel.alarmSignal;
    },

    alarm_acked() {
      return (
        this.channel.alarmSignal && !this.channel.alarmUnack
      ); // TODO fix the real prop
    },

    not_connected() {
      return !this.node.connected;
    },

    hint() {
      if (this.node && this.node.capability) {
        let hint = `${this.$appm.get_node_type_name(this.node.capability)} v${
          this.node.version_full
        }`;
        hint += `\n${this.$txt.serial_short}: ${this.node.serial}`;
        if (this.not_connected) {
          hint += `\n- ${this.$txt.not_connected}`;
        }
        if (this.alarm) {
          hint += `\n- ${this.$txt.alarm}`;
        }
        return hint;

      } return "";
    }
  }
};
</script>

<style lang="css" scoped></style>
